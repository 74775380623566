import {
  Article,
  BackgroundImage,
  Container,
  Headings,
  Section,
} from "@/atoms"
import { graphql, PageProps, useStaticQuery } from "gatsby"
import React from "react"
import tw from "twin.macro"
import { MdTrain } from "@react-icons/all-files/md/MdTrain";
import { MdPlace } from "@react-icons/all-files/md/MdPlace";
import { FaMap } from "@react-icons/all-files/fa/FaMap";

import { Address, BreadCrumbs, Layout, Map, NextSEO, SEO } from "@/components"
import profile from "@/configs/profile"
import { AccessPageQuery } from "@/gatsby-graphql"

export type AccessPageProps = PageProps<AccessPageQuery>

const Access: React.FC<AccessPageProps> = ({ data, location, path }) => {
  console.log(location);


  return (
    <Layout>
      {/* <SEO title="アクセス" /> */}
      <NextSEO
        type="Article"
        title="アクセス"
        description={
          "事務所へのアクセス"
        }
        url={`/access`}
        // TODO: add image
        breadCrumbs={[
          { title: "アクセス" },
        ]}
      />
      <BackgroundImage
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        header={<Headings variant="h2" as='h1'>アクセス</Headings>}
      />
      <Article maxWidth={'tablet'} css={{
        ...tw`space-y-12 pb-12 px-4`,
        '@tablet': { ...tw`px-0` }
      }}>
        <BreadCrumbs breadCrumbs={[{ title: "アクセス" }]} />
        <Section css={{
          ...tw`w-full mx-auto space-y-6`,
          "@tablet": { ...tw`w-full mx-auto space-y-8` }
        }} >
          <div css={{ ...tw`text-center space-y-2` }}>
            <FaMap css={{ ...tw`inline-block text-3xl` }} />
            <Headings variant='h3' as='h1'>Map</Headings>
          </div>
          <Map />
        </Section>
        <Section css={{
          ...tw`flex flex-col space-y-12`,
          '@tablet': {
            ...tw`flex flex-row space-y-0 space-x-8 justify-around`,
          }
        }}>
          <div css={{ ...tw`text-center space-y-4` }}>
            <MdPlace css={{ ...tw`inline-block text-3xl` }} />
            <Headings variant="h3" as="h2" >
              所在地
            </Headings>
            <Address />
          </div>
          <div css={{ ...tw`text-center space-y-4` }}>
            <MdTrain css={{ ...tw`inline-block text-3xl` }} />
            <Headings variant='h4' as='h2'>公共交通機関でお越しの場合</Headings>
            <ul css={{ ...tw`space-y-4` }}>
              <li>JR山手線/西武新宿線/東京メトロ東西線<br />高田馬場駅より徒歩約15分</li>
              <li>西武新宿線<br />下落合駅より徒歩約10分</li>
            </ul>
          </div>
        </Section>
      </Article>
    </Layout >
  )
}

export default Access

export const data = graphql`
    query AccessPage {
        placeholderImage: file(relativePath: { eq: "image4.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              formats: [WEBP]
            )
          }
        }
    }
  `